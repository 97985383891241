<template>
  <div class="gov-index">
    <div class="banner"></div>
    <HeaderNav active="/gov/index" :fixedHeight="200"></HeaderNav>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "GovIndex",
  components: {
    HeaderNav,
    Footer,
  }
};
</script>

<style lang="less">
.gov-index {
  width: 100%;
  height: 200px;
  .banner {
    background-image: url(../../assets/banner-zhengwu.jpg);
    height: 200px;
  }
}
</style>